import styled from "styled-components";

import Button from '../Button';
// import Input from '../Input';

export const Container = styled.div``;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 500;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
`;

export const Content = styled.div`
  background: #FFF;
  border-radius: 0 0 8px 8px;

  display: flex;
	flex-direction: column;
	padding: 20px;

  p {
    margin-bottom: 10px;
    text-align: center;
    color: #101633;
    font-size: 12px;
    color: #C90303;
  }

  h2 {
    margin: 0 0 8px 0;
    text-align: center;
    color: #101633;
    font-size: 12px;
  }
`;

export const Header = styled.div`
	background-color: #ef295c;
	width: 350px;
	height: 40px;
	border-radius: 8px 8px 0 0;
	color: #FFF;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const TitleHeader = styled.p`
	font-size: 15px;
`;

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
  background-color: red;
`;

export const ButtonModal = styled(Button)`
	margin-bottom: 10px;
  width: 100%;
`;



export const InputTextArea = styled.div`

	width: 100%;
	border: 2px solid #ef295c;
	height: 150px;
	border-radius: 8px;
	margin: 10px 0;

	label {
		position: absolute;
		margin-top: -10px;
		margin-left: 10px;
		background: #FFF;
		padding: 0 4px;
		font-weight: 500;
		color: #ef295c !important;
	}

	textarea {
		border: none;
		width: 100%;
		height: 145px;
		border-radius: 8px;
		color: #101633 !important;
		padding: 20px;
	}
`;

export const ImagePost = styled.img`
	border-radius: 8px;
	margin: 5px;
	width: 150px;
	margin: 0 auto;
	margin-bottom: 15px;
`;

export const ContentChoice = styled.div`

`;

export const ErrorMessage = styled.p`

`;