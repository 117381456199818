import { useState } from 'react';
import { Link } from 'react-router-dom';

import * as S from './styles';

import Modal from '../Modal';

import { ReactComponent as IconHome } from '../../assets/images/icon-home.svg';
import { ReactComponent as IconHistoric } from '../../assets/images/icon-historic.svg';
import { ReactComponent as IconSupport } from '../../assets/images/icon-support.svg';
import { ReactComponent as IconSendLikes } from '../../assets/images/icon-send-likes.svg';
import { ReactComponent as IconAccount } from '../../assets/images/icon-account.svg';

function Footer() {

	const [modalShow, setModalShow] = useState<boolean>(false);

	return (
		<>
			<S.Footer>
				<Link to="/profile">
					<IconHome stroke="white" />
				</Link>
				<Link to="/historic">
					<IconHistoric stroke="white" />
				</Link>

				<Link to={{ pathname: 'https://api.whatsapp.com/send?phone=5562994793650&text=Preciso%20de%20ajuda' }} target="_blank" rel="noopener noreferrer">
					<IconSupport />
				</Link>

				<button onClick={() => setModalShow(true)}>
					<IconSendLikes />
				</button>

				<Link to="/account">
					<IconAccount />
				</Link>
			</S.Footer>

			{modalShow && (
				<Modal
					modalShow={modalShow}
					modalHide={() => setModalShow(false)}
					type="link"
				>
				</Modal>
			)}</>

	);
}

export default Footer;