import styled from "styled-components";

import { FiInfo } from 'react-icons/fi';

import { HiRefresh } from 'react-icons/hi';

export const Container = styled.div`
	max-width: 550px;
	margin: 0 auto;
	padding: 10px;
`;

export const ContentBio = styled.div`
	color: #FFF;

	button {
		border: 0;
		background: transparent;
		color: #FFF;
		margin-top: 15px;
		font-size: 17px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
`;

export const FullName = styled.p`
	font-size: 14px;
	font-weight: bold;
`;

export const Bio = styled.p`
	font-size: 13px;
	font-weight: 500;
	margin-top: 5px;
`;

export const ContentPosts = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 20px 0 80px 0;
`;

export const Post = styled.img`
	width: 100%;
	margin: 5px 0;
	border-radius: 8px;
`;

export const ButtonSendLikes = styled.button`
	border: none;
	outline: none;
	width: 31%;
	cursor: pointer;
	margin-right: 8px;
	background: transparent;
`;

export const WarningNoProfile = styled.div`
	text-align: center;
	width: 85%;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const IconWarning = styled(FiInfo)`
	font-size: 35px;
	color: #FB2424;
`;

export const TextWarning = styled.p`
	font-size: 14px;
	margin-top: 10px;
	color: #101633;
`;

export const IconHiRefresh = styled(HiRefresh)`
	font-size: 16px;
	margin-right: 3px;
`;