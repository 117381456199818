import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';

import api from '../services/api';

interface User {
	id: number;
	name: string;
	email: string;
	phone: string;
}

interface AuthState {
	token: string;
	user: User;
}

interface Posts {
	shortcode: string;
	uri: string;
}

interface Profile {
	username: string;
	fullName: string;
	biography: string;
	profile: string;
	posts: Posts[];
}

interface SignInCredentials {
	email: string;
	password: string;
}

interface Subscriptions {
	id: number;
	profile: string;
	balance_likes: number;
	balance_views: number;
	status: string;
	type_service: string;
	name: string;
	description: string;
	payment: string;
	expiration_date: Date;
}

interface IProfileSelected {
	subscriptionId: number;
	profile: string;
	balanceLikes: number;
	balanceViews: number;
}

interface AuthContextData {
	user: User;
	subscriptions: Subscriptions[];
	handleSelectedProfile: (id: number, profile: string) => Promise<void>;
	handleGetSubscriptionProfileSelected: () => void,
	profileSelected: IProfileSelected,
	profile: Profile;
	signIn: (credentials: SignInCredentials) => Promise<void>;
	signOut: () => void;
	loading: boolean;
	noProfile: boolean;
	handleSetNoProfile: () => void;
	handleUploadPhotos: (profile: string, id: number) => Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {

	const [profile, setProfile] = useState<Profile>({} as Profile);
	const [loading, setLoading] = useState<boolean>(false);
	const [noProfile, setNoProfile] = useState<boolean>(false);
	const [subscriptions, setSubscriptions] = useState<Subscriptions[]>([]);
	const [profileSelected, setProfileSelected] = useState<IProfileSelected>({} as IProfileSelected);

	const [data, setData] = useState<AuthState>(() => {
		const token = localStorage.getItem('@IG:token');
		const user = localStorage.getItem('@IG:user');

		if (token && user) {

			api.defaults.headers.Authorization = `Bearer ${token}`;

			api.interceptors.response.use(
				(res) => res,

				async (err) => {
					console.log("Interceptor response error");

					if (err.response.status === 401) {

						localStorage.removeItem('@IG:token');
						localStorage.removeItem('@IG:user');

						setData({} as AuthState);
					}

					return Promise.reject(err);
				},
			);

			return { token, user: JSON.parse(user) }
		}

		return {} as AuthState;
	});

	useEffect(() => {

		if (!data.user) return;

		handleGetSubscriptions();

		handleGetSubscriptionProfileSelected();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	// NEW FUNCTIONS

	async function handleGetSubscriptions() {

		const { data } = await api.get('/subscriptions');

		setSubscriptions(data);
	}

	const handleSelectedProfile = useCallback(async (id, profile) => {

		await api.post(`/subscriptions/profile-selected/${id}`);

		try {
			setLoading(true);

			const response = await api.get(`/profile/${profile}`);

			setProfile(response.data.user);

			handleGetSubscriptionProfileSelected();

			setLoading(false);
		} catch (error: any) {

			if (error.response.data.message) {
				setLoading(false);
				setNoProfile(true);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function handleGetSubscriptionProfileSelected() {

		const profileSelected = await api.get('/subscriptions/profile-selected');

		const { subscriptionId, profile, balanceLikes, balanceViews } = profileSelected.data;

		setProfileSelected({ subscriptionId, profile, balanceLikes, balanceViews });

		if (!profile) return;

		handleUploadPhotos(profile, subscriptionId);

		const dataProfileSelected = await api.get(`/profile/${profile}`);

		setProfile(dataProfileSelected.data.user);
	}

	// END NEW FUNCTIONS

	const signIn = useCallback(async ({ email, password }) => {
		const response = await api.post('/auth', {
			email: email.trim().toLowerCase(),
			password
		}, {
			headers: {
				company: 'ig'
			}
		});

		const { token, user } = response.data;

		localStorage.setItem('@IG:token', token);
		localStorage.setItem('@IG:user', JSON.stringify(user));

		api.defaults.headers.Authorization = `Bearer ${token}`;

		setData({ token, user });
	}, []);

	const signOut = useCallback(() => {
		localStorage.removeItem('@IG:token');
		localStorage.removeItem('@IG:user');

		setData({} as AuthState);
		setProfile({} as Profile);
	}, []);

	const handleSetNoProfile = () => {

		setNoProfile(false);
	}

	const handleUploadPhotos = useCallback(async (username, id) => {

		setLoading(true);

		const photos = await api.post(`/profile/update/${username}`);

		setProfile(photos.data.user);

		handleUpdateIsPrivate(id, photos.data.user.profile);

		setLoading(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleUpdateIsPrivate = useCallback(async (id, profilePicUrl) => {

		await api.put(`/subscriptions/update-is-private`, { id, profilePicUrl });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthContext.Provider value={
			{
				user: data.user,
				subscriptions,
				handleSelectedProfile,
				handleGetSubscriptionProfileSelected,
				profileSelected,
				profile,
				signIn,
				signOut,
				loading,
				noProfile,
				handleSetNoProfile,
				handleUploadPhotos
			}
		}>
			{children}
		</AuthContext.Provider>
	);
}

function useAuth(): AuthContextData {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}

	return context;
}

export { AuthProvider, useAuth }