import { ReactNode } from 'react';

import * as S from './styles';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Loader from "react-loader-spinner";

interface ButtonProps {
	children: ReactNode;
	model: 'access' | 'register' | 'cancel';
	type: any;
	onClick?: () => any;
	loading?: boolean;
}

function Button({ type, model, children, loading, ...rest }: ButtonProps) {
	return (
		<S.ButtonContent type={type} model={model} {...rest}>
			{loading ? (
				<Loader
					type="TailSpin"
					color="#ffffff"
					height={25}
					width={25}
					timeout={0}
				/>
			) : children}
		</S.ButtonContent>
	);
}

export default Button;