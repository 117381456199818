import { useState } from "react";

import * as S from "./styles";

interface ModalProps {
	modalShow: boolean;
	modalHide: () => void;
	children: any;
	shortcode?: string;
	uri?: string;
	type?: string;
	subscriptionId: number;
	planName: string;
	planProfile: string;
	planEmail: string;
}

function ModalCancel({ modalShow, modalHide, subscriptionId, planName, planProfile, planEmail }: ModalProps) {

	const [reason, setReason] = useState<string>('');

	function handleLinkWhatsApp() {

		if (!reason) {
			alert('Por favor, informe o motivo!');

			return;
		}

		window.location.href = `https://api.whatsapp.com/send?1=pt_BR&phone=5562994793650&text=Desejo cancelar minha assinatura *${subscriptionId} - ${planName} Seguidores Instagram - ${planProfile} - ${planEmail} - Motivo:* ${reason}`;

		modalHide();
		setReason('');
	}

	return (
		<S.Container>
			{modalShow && (
				<>
					<S.Backdrop />
					<S.Wrapper>
						<S.Header>
							<S.TitleHeader>Cancelamento de plano</S.TitleHeader>
						</S.Header>
						<S.Content>
							<>
								<h2>Código: #{subscriptionId}</h2>
								<p>Após enviar o pedido, nosso suporte tem até 48h para efetivar o cancelamento.</p>
								<S.InputTextArea
								><label>* Motivo</label>
									<textarea
										placeholder="Informe o motivo do cancelamento"
										onChange={(event: any) => { setReason(event.target.value) }}
									></textarea>
								</S.InputTextArea>

								<S.ButtonModal
									type="button"
									model="access"
									onClick={() => handleLinkWhatsApp()}
								>ENVIAR CANCELAMENTO</S.ButtonModal>
							</>

							<S.ButtonModal
								type="button"
								model="cancel"
								onClick={() => modalHide()}
							>FECHAR</S.ButtonModal>
						</S.Content>
					</S.Wrapper>
				</>
			)}
		</S.Container>
	);
};

export default ModalCancel;