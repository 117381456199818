import styled from 'styled-components';

export const Footer = styled.div`
	background: #ef295c;
	position: fixed;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 550px;
	height: 70px;
	bottom: 0;
	border-radius: 8px 8px 0 0;
	margin-top: 50px;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;

	button {
		background: transparent;
		border: 0;
	}
`;