import { useEffect, useState } from 'react';

import { format } from 'date-fns';

import api from '../../services/api';

import * as S from './styles';

import { useAuth } from '../../hooks/auth';

import Header from '../../components/Header';

import Footer from '../../components/Footer';

interface HistoricProps {
	id: number;
	link: string;
	status: string;
	id_service: number;
	quantity: number;
	send_date: Date;
	type_service: string;
}

function Historic() {

	const { profileSelected } = useAuth();

	const [historic, setHistoric] = useState<HistoricProps[]>([]);
	const [totalLastDays] = useState('');

	useEffect(() => {

		if (!profileSelected.subscriptionId) return;

		handleHistorySent();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profileSelected]);

	const typeService: any = {
		'instagram_followers': 'Seguidores Instagram',
		'instagram_likes': 'Instagram Likes',
		'instagram_views': 'Instagram Views'
	}

	const typeStatus: any = {
		'Pending': 'Processando',
		'In progress': 'Processando',
		'Partial': 'Processando',
		'Awaiting': 'Processando',
		'Processing': 'Processando',
		'Completed': 'Completa',
		'Canceled': 'Cancelada'
	}

	function handleHistorySent() {

		api.get(`/historic/subscription/${profileSelected.subscriptionId}`).then(response => {

			setHistoric(response.data.historic);
			// setHistoryLastDays(response.data.lastDays);
			// setLoading(false);
		});
	}

	function formatDate(date: Date) {

		const d = new Date(date);
		const dateFormated = format(d, "dd/MM");

		return dateFormated;
	}

	return (
		<S.Container>
			<Header />
			<S.Content>

				<S.TotalLastDays>
					<p>Você recebeu <span>{totalLastDays}</span> seguidores nos últimos 7 dias</p>
				</S.TotalLastDays>

				<S.ContentTableHeader>
					<S.TableHeaderDate>
						<p>Data</p>
					</S.TableHeaderDate>
					<S.TableHeaderService>
						<p>Serviço</p>
					</S.TableHeaderService>
					<S.TableHeaderQuantity>
						<p>Qtde</p>
					</S.TableHeaderQuantity>
					<S.TableHeaderStatus>
						<p>Status</p>
					</S.TableHeaderStatus>
				</S.ContentTableHeader>

				{historic.map(item => (
					<S.ContentTableBody key={item.id}>
						<S.ContentBody>
							<S.TableHeaderDate>
								<p>{formatDate(item.send_date)}</p>
							</S.TableHeaderDate>
							<S.TableHeaderService>
								<p>{typeService[item.type_service]}</p>
							</S.TableHeaderService>
							<S.TableHeaderQuantity>
								<p>{item.quantity}</p>
							</S.TableHeaderQuantity>
							<S.TableBodyStatus status={item.status}>
								<p>{typeStatus[item.status]}</p>
							</S.TableBodyStatus>
						</S.ContentBody>
						<S.ContentTableFooter>
							<div>
								{item.type_service === 'instagram_likes' ? 'Curtidas enviadas para: ' : 'Visualizações enviadas para: '}
								<span> {item.link} </span>
							</div>
						</S.ContentTableFooter>
					</S.ContentTableBody>
				))}

				<S.InfoFooterHistoric>
					<p>Listamos o histórico apenas dos últimos 15 dias</p>
				</S.InfoFooterHistoric>
			</S.Content>
			<Footer />
		</S.Container>
	);
}

export default Historic;