import React from 'react';

import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import Profile from '../pages/Profile';
import Account from '../pages/Account';
import Historic from '../pages/Historic';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

const Routes: React.FC = () => {
	return (
		<Switch>
			<Route path="/" exact component={SignIn} />
			<Route path="/forgot-password" component={ForgotPassword} />
			<Route path="/reset-password/token/:token/email/:email" component={ResetPassword} />
			<Route path="/profile" component={Profile} isPrivate />
			<Route path="/account" component={Account} isPrivate />
			<Route path="/historic" component={Historic} isPrivate />
		</Switch>
	);
}

export default Routes;