import styled, { css } from 'styled-components';

import { FiEdit, FiXCircle } from 'react-icons/fi';

import Button from '../../components/Button';

import Input from '../../components/Input';

interface ButtonProps {
	bg: string;
}

interface MessageProps {
	color: string;
}

interface StatusProps {
	status: string;
}

export const Container = styled.div`
	min-height: 100vh;
	background: #ef295c;
	padding: 10px;
`;

export const Content = styled.div`
	max-width: 550px;
	margin: 0 auto;
`;

export const Title = styled.p`
	color: #FFF;
	font-size: 14px;
	margin: 10px 0 8px 15px;
`;

export const Card = styled.div`
	background: #FFF;
	border-radius: 8px;
	box-shadow: 0px 10px 20px rgba(71, 100, 230, 0.18), 0px 2px 6px rgba(71, 100, 230, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
	padding: 10px 15px;
	margin-bottom: 10px;
	position: relative;
`;

export const Status = styled.div<StatusProps>`
	height: 12px;
	width: 12px;
	border-radius: 20px;
	position: absolute;
	right: 15px;

	${props => props.status === 'ACTIVE' && css`
		background: #7EF54E;
		animation-name: active;
		animation-duration: 1.8s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;

		@keyframes active {
			0% {
				transform: scale(.7);
				background-color: #31A204;
				border-radius: 100%;
			}
			50% {
				background-color: #236A06;
			}
		}
	`}

	${props => props.status === 'ACTIVE_LIKES' && css`
		background: #9D7BB4;
		animation-name: stretch;
		animation-duration: 1.8s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;

		@keyframes stretch {
			0% {
				transform: scale(.7);
				background-color: #ef295c;
				border-radius: 100%;
			}
			50% {
				background-color: #4F0283;
			}
		}
	`}

	${props => props.status === 'ACTIVE_CANCELED' && css`
		background: #FB998C;
		animation-name: canceled;
		animation-duration: 1.8s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;

		@keyframes canceled {
			0% {
				transform: scale(.7);
				background-color: #DB412C;
				border-radius: 100%;
			}
			50% {
				background-color: #8C1100;
			}
		}
	`}
`;

export const TextCard = styled.p`
	color: #101633;
	font-size: 13px;
	margin: 4px 0;
`;

export const TextCardWarning = styled.p<StatusProps>`
	font-size: 13px;
	margin: 4px 0;

	${props => props.status === 'ACTIVE_CANCELED' && css`
		color: #db412c;
	`}

	${props => props.status === 'ACTIVE_LIKES' && css`
		color: #ef295c;
	`}
`;

export const Hr = styled.hr`
	height: 1px;
	background: #ECECEC; 
	border: none;
	margin: 8px 0;
`;

export const ContentButtonsCard = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const ButtonCard = styled.button<ButtonProps>`
	background: #FFF;
	color: ${props => props.bg};
	border: none;
	margin-left: 10px;
	padding: 3px 4px;
	border-radius: 3px;
	cursor: pointer;
`;

export const TextButtonCard = styled.p`
	font-size: 12px;
`;

export const IconButtonChange = styled(FiEdit)`
	font-size: 14px;
	margin-bottom: -2px;
	margin-right: 2px;
`;

export const IconButtonCancel = styled(FiXCircle)`
	font-size: 14px;
	margin-bottom: -2px;
	margin-right: 2px;
`;

export const ButtonChangePassword = styled(Button)`
	&& {
		width: 100%;
 	}
`;

export const InputChangePassword = styled(Input)`
	width: 100%;
`;

export const MessageUpdate = styled.h2<MessageProps>`
	text-align: center;
	font-size: 13px;
	color: ${props => props.color};
	margin: 10px 0;
`;