import { FormEvent, useState } from 'react';

import { Link } from 'react-router-dom';

import * as S from './styles';

import logo from '../../assets/images/logo-panel.svg';

import api from '../../services/api';

export default function ForgotPassword() {

	const [email, setEmail] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [message, setMessage] = useState<boolean>(false);

	function handleForgotPassword(event: FormEvent) {
		event.preventDefault();

		setLoading(true);

		if (email === '') {
			setError('E-mail é obrigatório');
			setLoading(false);

			return;
		}

		api.post('/users/forgot-password', {
			email
		}, {
			headers: {
				company: 'ig'
			}
		}).then(response => {
			setMessage(true);
		}).catch(error => {
			setLoading(false);
			setMessage(true);
		});
	}

	return (
		<S.Container>
			<S.Header>
				<S.Logo src={logo} />
			</S.Header>

			<S.ContentForm onSubmit={handleForgotPassword}>
				{message && (
					<S.ContentForgotPassword>
						<S.IconBiMailSend />
						<h1>Verifique seu e-mail</h1>
						<span>As orientações foram enviadas para: {email}</span>
					</S.ContentForgotPassword>
				)}

				{!message && (
					<>
						<S.Title>Esqueceu sua senha?</S.Title>

						<S.InputForm
							label="E-mail"
							type="text"
							placeholder="Digite seu e-mail"
							onChange={(event: any) => {
								setEmail(event.target.value);
								setError('');
							}}
						/>

						{error && (
							<p>{error}</p>
						)}

						<S.ButtonAccess
							type="submit"
							model="access"
							loading={loading}
						>
							ENVIAR
						</S.ButtonAccess>
					</>
				)}

				<Link to="/">
					<S.ForgotPassword>
						Voltar para o login
					</S.ForgotPassword>
				</Link>
			</S.ContentForm>
		</S.Container>
	);
}