import styled, { css } from 'styled-components';

interface Status {
	status: string;
}

export const Container = styled.div`
	max-width: 550px;
	margin: 0 auto;
	padding: 10px;
`;

export const Content = styled.div`
	margin-bottom: 90px;
`;

export const ContentTableHeader = styled.div`
	background: #FFF;
	display: flex;
	border-radius: 8px;
	box-shadow: 0px 10px 20px rgb(71 100 230 / 18%), 0px 2px 6px rgb(71 100 230 / 12%), 0px 0px 1px rgb(0 0 0 / 4%);
	padding: 7px;
	color: #101633;
	font-size: 14px;
	font-weight: 600;
`;

export const ContentTableBody = styled.div`
	background: #FFF;
	border-radius: 8px;
	box-shadow: 0px 10px 20px rgb(71 100 230 / 18%), 0px 2px 6px rgb(71 100 230 / 12%), 0px 0px 1px rgb(0 0 0 / 4%);
	padding: 7px;
	color: #101633;
	font-size: 11px;
	font-weight: 600;
	margin: 10px 0;
`;

export const TotalLastDays = styled.div`
	text-align: center;
	color: #FFF;
	margin-bottom: 15px;
	p {
		margin: 0;
		font-size: 16px;
		font-weight: 400;

		span {
			font-weight: 700;
    		font-size: 20px;
		}
	}
`;

export const InfoFooterHistoric = styled.div`
	margin-top: 15px;
	
	p {
		color: #8e8e90;
    	font-size: 12px;
    	text-align: center;
	}
`;

export const ContentBody = styled.div`
	display: flex;
`;

export const ContentTableFooter = styled.div`
	font-weight: 500;
	margin-top: 10px;

	span {
		font-weight: 600;
	}

	.seePost {
		color: #ef295c;
		font-weight: 500;
		text-decoration: none;
	}
`;

export const TableHeaderDate = styled.div`
	width: 12%;
`;

export const TableHeaderService = styled.div`
	width: 55%;
`;

export const TableHeaderQuantity = styled.div`
	width: 12%;
`;

export const TableHeaderStatus = styled.div`
	width: 25%;

	div {
		font-size: 10px;
		background: #31a204;
		color: #fff;
		padding: 1px 2px;
		width: 83px;
		border-radius: 14px;
		text-align: center;
	}
`;

export const TableBodyStatus = styled.div<Status>`
	width: 25%;
	font-size: 10px;
	color: #fff;
	padding: 2px 4px;
	width: 83px;
	border-radius: 14px;
	text-align: center;

	${props => props.status === 'Completed' && css`
		background: #31a204;
	`}

	${props => props.status === 'Canceled' && css`
		background: #c70303;
	`}

	${props => props.status === null && css`
		background: #ef295c;
	`}

	${props => props.status === 'Pending' && css`
		background: #ef295c;
	`}

	${props => props.status === 'In progress' && css`
		background: #ef295c;
	`}

	${props => props.status === 'Partial' && css`
		background: #ef295c;
	`}

	${props => props.status === 'Processing' && css`
		background: #ef295c;
	`}
`;