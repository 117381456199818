import styled, { css } from 'styled-components';

interface modelProps {
	model: 'access' | 'register' | 'cancel';
}

export const ButtonContent = styled.button<modelProps>`
	box-shadow: 0px 10px 20px rgba(71, 100, 230, 0.18), 0px 2px 6px rgba(71, 100, 230, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
	color: #FFF;
	height: 55px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 5px 0;
	border: 0;

	${({ model }) => model === 'access' && css`
		background: #ef295c;
	`}

	${({ model }) => model === 'register' && css`
		background: #C23E9F;
	`}

	${({ model }) => model === 'cancel' && css`
		background-color: transparent;
		border: 2px solid #C23E9F;
		color: #C23E9F;
	`}

	transition: filter 0.2s;

	&:hover {
		filter: brightness(0.8);
	}
`;
