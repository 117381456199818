import { useState } from "react";

import api from "../../services/api";

import { useAuth } from '../../hooks/auth';

import * as S from "./styles";

interface ModalProps {
	modalShow: boolean;
	modalHide: () => void;
	children: any;
	shortcode?: string;
	uri?: string;
	type?: string;
}

function Modal({ modalShow, modalHide, shortcode, uri, type, children }: ModalProps) {

	const [quantity, setQuantity] = useState<number>();
	const [link, setLink] = useState<string>();
	const [contentLikes, setContentLikes] = useState<boolean>(false);
	const [contentViews, setContentViews] = useState<boolean>(false);
	const [contentChoice, setContentChoice] = useState<boolean>(true);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	const { profileSelected, handleGetSubscriptionProfileSelected } = useAuth();

	function handleSendService(typeService: string) {

		if (!quantity) {
			setErrorMessage('Digite a quantidade');

			return;
		}

		if (type && !link) {
			setErrorMessage('Insira o link');

			return;
		}

		setLoading(true);

		api.post(`/subscriptions/send-service/${typeService}`, {
			subscriptionId: profileSelected.subscriptionId,
			quantity: quantity,
			link: type ? link : `https://instagram.com.br/p/${shortcode}`
		}).then(response => {

			if (response.status === 200) {

				handleGetSubscriptionProfileSelected();
			}

			setQuantity(0);
			setLink('');
			setLoading(false);
			modalHide();
		}).catch(error => {

			setLoading(false);

			if (error.response.data.message === 'Insufficient funds') {
				setErrorMessage('Saldo insuficiente');
			}

			if (error.response.data.message === 'Maximum quantity exceeded') {
				setErrorMessage('Quantidade máxima permitida: 1000');
			}

			if (error.response.data.message === 'Quantity less than minimal 20') {
				setErrorMessage('Quantidade mínima permitida: 20');
			}

			if (error.response.data.message === 'You have active order with this link. Please wait until order being completed.') {
				setErrorMessage('Você tem um pedido ativo com este link. Por favor, espere até que o pedido seja concluído.');
			}
		})
	}

	function changeTitleModal() {
		if (contentChoice) {
			return 'Selecione o tipo de serviço'
		}

		if (contentLikes) {
			return `Saldo de curtidas: ${profileSelected.balanceLikes}`;
		}

		if (contentViews) {
			return `Saldo de visualizações: ${profileSelected.balanceViews}`;
		}
	}

	return (
		<S.Container>
			{modalShow && (
				<>
					<S.Backdrop />
					<S.Wrapper>
						<S.Header>
							<S.TitleHeader>{type ? changeTitleModal() : `Saldo de curtidas: ${profileSelected.balanceLikes}`}</S.TitleHeader>
						</S.Header>
						<S.Content>
							{type && contentChoice && (
								<S.ContentChoice>
									<h2>Selecione o que deseja enviar:</h2>
									<p>* Os serviços permitidos são: Feed, Reels e IGTV</p>

									<S.ButtonModal
										type="button"
										model="access"
										onClick={() => {
											setContentChoice(false);
											setContentViews(false);
											setContentLikes(true)
										}}
									>ENVIAR CURTIDAS</S.ButtonModal>

									<S.ButtonModal
										type="button"
										model="access"
										onClick={() => {
											setContentChoice(false);
											setContentViews(true);
											setContentLikes(false)
										}}
									>ENVIAR VISUALIZAÇÕES</S.ButtonModal>
								</S.ContentChoice>
							)}

							{!type && (
								<S.ImagePost src={uri} />
							)}

							{type && contentLikes && (
								<>
									<h2>Você está enviando curtidas!</h2>
									<p>* Os serviços permitidos são: Feed, Reels e IGTV</p>

									<S.InputText
										type="text"
										label="Link para curtidas"
										placeholder="Insira o link da publicação"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setLink(e.target.value);
											setErrorMessage('');
										}}
									/>

									<S.InputText
										type="number"
										label="Quantidade"
										placeholder="Digite a quantidade (min: 20)"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setQuantity(Number(e.target.value));
											setErrorMessage('');
										}}
									/>

									<S.ButtonModal
										type="button"
										model="access"
										loading={loading}
										onClick={() => handleSendService('likes')}
									>ENVIAR CURTIDAS</S.ButtonModal>
								</>
							)}

							{type && contentViews && (
								<>
									<h2>Você está enviando visualizações!</h2>
									<p>* Os serviços permitidos são: Feed, Reels e IGTV</p>

									<S.InputText
										type="text"
										label="Link para visualizações"
										placeholder="Insira o link da publicação"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setLink(e.target.value);
											setErrorMessage('');
										}}
									/>

									<S.InputText
										type="number"
										label="Quantidade"
										placeholder="Digite a quantidade (min: 100)"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setQuantity(Number(e.target.value));
											setErrorMessage('');
										}}
									/>

									<S.ButtonModal
										type="button"
										model="access"
										loading={loading}
										onClick={() => handleSendService('views')}
									>ENVIAR VISUALIZAÇÕES</S.ButtonModal>
								</>
							)}

							{!type && (
								<>
									<S.InputText
										type="number"
										label="Quantidade"
										placeholder="Digite a quantidade"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setQuantity(Number(e.target.value));
											setErrorMessage('');
										}}
									/>

									<S.ButtonModal
										type="button"
										model="access"
										loading={loading}
										onClick={() => handleSendService('likes')}
									>ENVIAR CURTIDAS</S.ButtonModal>
								</>
							)}

							{errorMessage && (
								<S.ErrorMessage>{errorMessage}</S.ErrorMessage>
							)}

							<S.ButtonModal
								type="button"
								model="cancel"
								onClick={() => modalHide()}
							>CANCELAR</S.ButtonModal>

						</S.Content>
					</S.Wrapper>
				</>
			)}
		</S.Container>
	);
};

export default Modal;