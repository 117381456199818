import { FormEvent, useState } from 'react';

import * as S from './styles';

import logo from '../../assets/images/logo-panel.svg';
import { useAuth } from '../../hooks/auth';
import { Link } from 'react-router-dom';

export default function SignIn() {

	const { signIn } = useAuth();

	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	async function handleAuth(event: FormEvent) {
		event.preventDefault();

		try {

			setLoading(true);

			if (email === '' || password === '') {
				setError('E-mail e senha obrigatório');
				setLoading(false);

				return;
			}

			await signIn({ email, password });
			setLoading(true);
		} catch (error: any) {
			setLoading(false);

			setError('E-mail ou senha inválidos');
		}
	}

	return (
		<S.Container>
			<S.Header>
				<S.Logo src={logo} />
			</S.Header>

			<S.ContentForm onSubmit={handleAuth}>
				<S.InputForm
					label="E-mail"
					type="text"
					placeholder="Digite seu e-mail"
					onChange={(event: any) => {
						setEmail(event.target.value);
						setError('');
					}}
				/>

				<S.InputForm
					label="Senha"
					type="password"
					placeholder="Digite sua senha"
					onChange={(event: any) => {
						setPassword(event.target.value);
						setError('');
					}}
				/>

				{/* <S.ForgotPassword>
					Esqueci minha senha
				</S.ForgotPassword> */}

				{error && (
					<p>{error}</p>
				)}

				<S.ButtonAccess
					type="submit"
					model="access"
					loading={loading}
				>
					ENTRAR
				</S.ButtonAccess>

				<Link to="/forgot-password">
					<S.ForgotPassword>
						Esqueci minha senha
					</S.ForgotPassword>
				</Link>
			</S.ContentForm>



			{/* <S.ButtonRegister
				type="button"
				model="register"
				onClick={() => { }}
			>
				CADASTRAR
			</S.ButtonRegister> */}
		</S.Container>
	);
}