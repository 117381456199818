import * as S from "./styles";

interface ModalMessageProps {
	children: any;
	modalMessageShow: boolean;
	modalMessageHide: () => void;
	title: string;
}

function ModalMessage({ modalMessageShow, modalMessageHide, title, children }: ModalMessageProps) {

	return (
		<S.Container>
			{modalMessageShow && (
				<>
					<S.Backdrop />
					<S.Wrapper>
						<S.Header>
							<S.TitleHeader>{title}</S.TitleHeader>
						</S.Header>

						<S.Content>
							{children}
						</S.Content>
					</S.Wrapper>
				</>
			)}
		</S.Container>
	);
};

export default ModalMessage;