// import { useState } from "react";
// import api from "../../services/api";

// import { useAuth } from '../../hooks/auth';

import * as S from "./styles";

interface ModalProps {
	modalShow: boolean;
	modalHide: () => void;
	children: any;
	shortcode?: string;
	uri?: string;
	type?: string;
}

function ModalNotice({ modalShow, modalHide, shortcode, uri, type, children }: ModalProps) {

	// const plan = JSON.parse(localStorage.getItem('@AG:plan') || '{}');

	// const [quantity, setQuantity] = useState<number>();
	// const [link, setLink] = useState<string>();

	// const [contentLikes, setContentLikes] = useState<boolean>(false);
	// const [contentViews, setContentViews] = useState<boolean>(false);
	// const [contentChoice, setContentChoice] = useState<boolean>(true);
	// const [errorMessage, setErrorMessage] = useState<string>('');
	// const [loading, setLoading] = useState<boolean>(false);

	// const { handleGetProfile } = useAuth();

	// function handleSendLikes() {

	// 	if (!quantity) {
	// 		setErrorMessage('Digite a quantidade');

	// 		return;
	// 	}

	// 	if (type && !link) {
	// 		setErrorMessage('Insira o link');

	// 		return;
	// 	}

	// 	setLoading(true);

	// 	api.post('/plans/sendlikes', {
	// 		id: plan.id,
	// 		quantity: quantity,
	// 		link: type ? link : `https://instagram.com.br/p/${shortcode}`
	// 	}).then(response => {


	// 		if (response.status === 200) {
	// 			handleGetProfile(plan.id, plan.profile, response.data.balance, plan.balance_views)
	// 		}
	// 		setQuantity(0);
	// 		setLink('');
	// 		setLoading(false);
	// 		modalHide();
	// 	}).catch(error => {
	// 		setLoading(false);

	// 		if (error.response.data.message === 'Insufficient funds') {
	// 			setErrorMessage('Saldo insuficiente');
	// 		}

	// 		if (error.response.data.message === 'Maximum quantity exceeded') {
	// 			setErrorMessage('Quantidade máxima permitida: 1000');
	// 		}

	// 		if (error.response.data.message === 'Quantity less than minimal 20') {
	// 			setErrorMessage('Quantidade mínima permitida: 20');
	// 		}

	// 		if (error.response.data.message === 'You have active order with this link. Please wait until order being completed.') {
	// 			setErrorMessage('Você tem um pedido ativo com este link. Por favor, espere até que o pedido seja concluído.');
	// 		}
	// 	})
	// }

	// function handleSendViews() {

	// 	if (!quantity) {
	// 		setErrorMessage('Digite a quantidade');

	// 		return;
	// 	}

	// 	if (!link) {
	// 		setErrorMessage('Insira o link');

	// 		return;
	// 	}

	// 	setLoading(true);

	// 	api.post('/plans/sendViews', {
	// 		id: plan.id,
	// 		quantity: quantity,
	// 		link
	// 	}).then(response => {
	// 		if (response.status === 200) {
	// 			handleGetProfile(plan.id, plan.profile, plan.balance, response.data.balance_views)
	// 		}

	// 		setQuantity(0);
	// 		setLink('');
	// 		setLoading(false);
	// 		modalHide();
	// 	}).catch(error => {
	// 		setLoading(false);

	// 		if (error.response.data.message === 'Insufficient funds') {
	// 			setErrorMessage('Saldo insuficiente');
	// 		}

	// 		if (error.response.data.message === 'Maximum quantity exceeded') {
	// 			setErrorMessage('Quantidade máxima permitida: 1000');
	// 		}

	// 		if (error.response.data.message === 'Quantity less than minimal 100') {
	// 			setErrorMessage('Quantidade mínima permitida: 100');
	// 		}
	// 	})
	// }

	// function changeTitleModal() {
	// 	if (contentChoice) {
	// 		return 'Selecione o tipo de serviço'
	// 	}

	// 	if (contentLikes) {
	// 		return `Saldo de curtidas: ${plan.balance}`;
	// 	}

	// 	if (contentViews) {
	// 		return `Saldo de visualizações: ${plan.balance_views}`;
	// 	}
	// }

	function handleLinkWhatsApp() {

		window.location.href = `https://api.whatsapp.com/send?1=pt_BR&phone=5562994793650&text=Novo%20n%C3%BAmero%20de%20suporte%20do%20Ag%C3%AAnciagram`;

		modalHide();
	}

	return (
		<S.Container>
			{modalShow && (
				<>
					<S.Backdrop />
					<S.Wrapper>
						<S.Header>
							<S.TitleHeader>WhatsApp Suporte</S.TitleHeader>
						</S.Header>
						<S.Content>

							{/* <S.ContentChoice> */}
							<p>AVISO!</p>
							<h2>Tiivemos um imprevisto com nosso número do suporte: (62) 99479-3650 e agora estamos somente com esse: 62 99357-7007.</h2>
							<h2>Pedimos desculpas pelo transtorno!</h2>
							{/* </S.ContentChoice> */}

							{/* 
							<S.ButtonModal
										type="button"
										model="access"
										onClick={() => {
											setContentChoice(false);
											setContentViews(true);
											setContentLikes(false)
										}}
									>ENVIAR VISUALIZAÇÕES</S.ButtonModal> 
							*/}

							<S.ButtonModal
								type="button"
								model="access"
								onClick={() => handleLinkWhatsApp()}
							>Falar com novo número: 62 99357-7007</S.ButtonModal>

							<S.ButtonModal
								type="button"
								model="cancel"
								onClick={() => modalHide()}
							>FECHAR</S.ButtonModal>

						</S.Content>
					</S.Wrapper>
				</>
			)}
		</S.Container>
	);
};

export default ModalNotice;