import { useState } from 'react';

import { useAuth } from '../../hooks/auth';

import * as S from './styles';

import Modal from '../../components/Modal';

import ModalNotice from '../../components/ModalNotice';

import Footer from '../../components/Footer';

import Header from '../../components/Header';

function Profile() {

	const [modalShow, setModalShow] = useState<boolean>(false);
	const [modalShowNotice, setModalShowNotice] = useState<boolean>(false);
	const [shortcode, setShortcode] = useState<string>('');
	const [uri, setUri] = useState<string>('');

	function handleModalLikes(shortcode: string, uri: string) {
		setShortcode(shortcode);
		setUri(uri);
		setModalShow(true)
	}

	const { profile, handleUploadPhotos, profileSelected } = useAuth();

	return (
		<S.Container>
			<Header />

			<S.ContentBio>
				<S.FullName>{profile.fullName}</S.FullName>
				<S.Bio>{profile.biography}</S.Bio>

				<button
					onClick={() => handleUploadPhotos(profile.username, profileSelected.subscriptionId)}
				>
					<S.IconHiRefresh />
					Atualizar fotos
				</button>
			</S.ContentBio>

			<S.ContentPosts>
				{profile.posts && profile.posts.map(post => (
					<S.ButtonSendLikes key={post.shortcode} onClick={() => handleModalLikes(post.shortcode, post.uri)}>
						<S.Post src={post.uri} />
					</S.ButtonSendLikes>
				))}
			</S.ContentPosts>

			{!profile.username && (
				<S.WarningNoProfile>
					<S.IconWarning />
					<S.TextWarning>É necessário selecionar um perfil para listar as informações!</S.TextWarning>
				</S.WarningNoProfile>
			)}
			<Footer />

			{modalShow && (
				<Modal
					modalShow={modalShow}
					modalHide={() => setModalShow(false)}
					shortcode={shortcode}
					uri={uri}
				>
				</Modal>
			)}

			{modalShowNotice && (
				<ModalNotice
					modalShow={modalShowNotice}
					modalHide={() => setModalShowNotice(false)}
					shortcode={shortcode}
					uri={uri}
				>
				</ModalNotice>
			)}
		</S.Container>
	);
}

export default Profile;

