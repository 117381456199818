import styled from 'styled-components';

import Input from '../../components/Input';
import Button from '../../components/Button';

export const Container = styled.div`
	max-width: 550px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
`;

export const Header = styled.div`
	display: flex;
	justify-content: center;
`;

export const Logo = styled.img`
	width: 240px;
	margin-top: -30px;
	margin-bottom: 40px;
`;

export const ContentForm = styled.form`
	background: #FFFFFF;
	width: 80%;
	margin: 40px auto;

	box-shadow: 0px 10px 20px rgba(71, 100, 230, 0.18), 0px 2px 6px rgba(71, 100, 230, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
	padding: 20px 30px; 
	border-radius: 8px;

	display: flex;
	flex-direction: column;
	justify-content: center;

	p {
		color: #FB2424;
		text-align: center;
		margin: 8px 0; 
	}
`;

export const InputForm = styled(Input)`
	&& {
		width: 100%;
 	}
`;

export const ButtonAccess = styled(Button)`
	width: 100%;
`;

export const ForgotPassword = styled.p`
	color: #101633 !important;
	margin-top: 20px !important;
	font-size: 13px;
	cursor: pointer;
`;

export const ButtonRegister = styled(Button)`
	/* width: 100%; */
`;

// export const ContentRegister = styled.div`
// 	background: red;
// 	width: 100%;
// 	margin: 30px auto;

// 	box-shadow: 0px 10px 20px rgba(71, 100, 230, 0.18), 0px 2px 6px rgba(71, 100, 230, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
// 	border-radius: 8px;

// 	display: flex;
// 	justify-content: center;
// `;
