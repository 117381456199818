import styled from 'styled-components';

import { FiChevronDown, FiHeart, FiEye, FiAlertTriangle } from 'react-icons/fi';

import { BsQuestion } from 'react-icons/bs';

import Button from '../Button';

export const HeaderContent = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 0 20px 0;
	position: relative;
`;

export const Profile = styled.div`
	display: flex;
	align-items: center;
`;

export const ImageProfile = styled.img`
	width: 55px;
	height: 55px;
	border-radius: 50%;
`;

export const NoImageProfile = styled(BsQuestion)`
	width: 55px;
	height: 55px;
	border-radius: 50%;
	font-size: 100px;
	color: #FFF;
	color: #ef295c;
	background: #FFF;
`;

export const ButtonNameProfile = styled.button`
	margin-left: 8px;
	border: 0;
	background: transparent;
`;

export const TextButtonNameProfile = styled.p`
	font-size: 14px;
	color: #FFF;
`;

export const IconChevronDown = styled(FiChevronDown)`
	color: #FFF;
	font-size: 18px;
`;

export const ContentBalance = styled.div`

`;

export const BalanceLikesAndViews = styled.div`
	display: flex;
	color: #FFF;
	margin-top: 5px;
	display: flex;
	justify-content: flex-end;
`;

export const IconBalanceLikes = styled(FiHeart)`
	font-size: 16px;
`;

export const IconBalanceViews = styled(FiEye)`
	font-size: 16px;
`;

export const Balance = styled.p`
	font-size: 13px;
	margin-right: 5px;
`;

export const Menu = styled.div`
	background: #FFF;
	position: absolute;
	z-index: 9;
	width: 170px;
	top: 60px;
	left: 35px;
	padding: 10px 0;
	border-radius: 8px;
	box-shadow: 0px 10px 20px rgba(71, 100, 230, 0.18), 0px 2px 6px rgba(71, 100, 230, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);

	button {
		
		padding: 5px 8px;
		cursor: pointer;
		border: none;
		background: transparent;
		width: 100%;
		text-align: start;
		display: flex;
		align-items: center;

		h1 {
			font-size: 14px;
			color: #101633;

		}

		div {
			background: red;
			height: 10px;
			width: 10px;
			margin-right: 5px;
			border-radius: 20px;
		}
		

		&:hover {
			background: #F2F5FF;
		}
	}
`;

export const LoadInfo = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.6);
	z-index: 500;

  div {
	position: fixed;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 700;
	width: inherit;
	outline: 0;

	width: 200px;
	height: 200px;
	background: red;
  }
`;

export const ContentModalMessage = styled.div`

	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	p {
		color: #101633 !important;	
		margin-top: 15px;

		span {
			color: #000;
		}
	}
`;

export const IconFiAlertTriangle = styled(FiAlertTriangle)`
	font-size: 30px;
	color: #c70303;
`;

export const ButtonCloseModalMessage = styled(Button)`
	width: 100%;
`;

