import { useState } from 'react';

import { useAuth } from '../../hooks/auth';

import * as S from './styles';

import Header from '../../components/Header';

import Footer from '../../components/Footer';

import api from '../../services/api';

import ModalCancel from '../../components/ModalCancel';

function Account() {

	const { user, subscriptions } = useAuth();

	const [password, setPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [message, setMessage] = useState<string>('');
	const [modalCancelShow, setModalCancelShow] = useState<boolean>(false);
	const [subscriptionId, setSubscriptionId] = useState<number>(0);
	const [planName, setPlanName] = useState<string>('');
	const [planProfile, setPlanProfile] = useState<string>('');
	const [planEmail, setPlanEmail] = useState<string>('');

	function handleUpdatePassword() {

		if (!password || !confirmPassword) {
			setMessage('Informe a senha e a confirmação');

			return;
		}

		if (password !== confirmPassword) {
			setMessage('As senhas não conferem');

			return;
		}

		setLoading(true);

		api.put('/users/account/reset-password', { password }).then(response => {
			setLoading(false);
			setMessage('Senha alterada com sucesso');
		});
	}

	function handleCancelPlan(subscriptionId: number, planName: string, planProfile: string, planEmail: string) {

		setSubscriptionId(subscriptionId);
		setPlanName(planName);
		setPlanProfile(planProfile);
		setPlanEmail(planEmail);

		setModalCancelShow(true);
	}

	return (
		<S.Container>
			<S.Content>
				<Header />

				<S.Title>Dados da conta</S.Title>
				<S.Card>
					<S.TextCard>Código: {user.id}</S.TextCard>
					<S.TextCard>Nome: {user.name}</S.TextCard>
					<S.TextCard>E-mail: {user.email}</S.TextCard>
					{/* <S.TextCard>Telefone: {maskPhone(user.phone)}</S.TextCard> */}
				</S.Card>

				<S.Title>Alterar senha</S.Title>

				<S.Card>
					<S.InputChangePassword
						label="Nova senha"
						type="password"
						placeholder="Digite sua nova senha"
						onChange={(event: any) => {
							setPassword(event.target.value);
							setMessage('');
						}}
					/>
					<S.InputChangePassword
						label="Confirme a nova senha"
						type="password"
						placeholder="Confirme a nova senha"
						onChange={(event: any) => {
							setConfirmPassword(event.target.value);
							setMessage('');
						}}
					/>

					{message === 'Senha alterada com sucesso' ? (
						<S.MessageUpdate color="#256c08">{message}</S.MessageUpdate>
					) : (
						<S.MessageUpdate color="#c70303">{message}</S.MessageUpdate>
					)}

					<S.ButtonChangePassword
						type="button"
						model="access"
						loading={loading}
						onClick={() => handleUpdatePassword()}>
						ALTERAR
					</S.ButtonChangePassword>
				</S.Card>

				<S.Title>Meus planos ativos</S.Title>

				{subscriptions.map(item => (
					<S.Card key={item.id}>
						<S.Status status={item.status} />
						<S.TextCard>{item.name} - {item.type_service}</S.TextCard>
						<S.TextCard>Descrição: {item.description}</S.TextCard>
						<S.TextCard>Perfil: {item.profile}</S.TextCard>
						<S.TextCard>Tipo de pagamento: {item.payment}</S.TextCard>
						<S.TextCard>
							Data de vencimento: {new Intl.DateTimeFormat('pt-BR').format(
								new Date(item.expiration_date)
							)}
						</S.TextCard>

						{item.status === 'ACTIVE_CANCELED' && (
							<S.TextCardWarning status={item.status}>
								Aviso: este plano foi cancelado, mas continuará recebendo os seguidores até {new Intl.DateTimeFormat('pt-BR').format(
									new Date(item.expiration_date)
								)}
							</S.TextCardWarning>
						)}

						{item.status === 'ACTIVE_LIKES' && (
							<S.TextCardWarning status={item.status}>
								Aviso: este plano está disponível apenas para Curtidas e Views
							</S.TextCardWarning>
						)}

						<S.Hr />

						<S.ContentButtonsCard>
							<S.ButtonCard bg="#ef295c">
								<S.TextButtonCard>
									<S.IconButtonChange />
									Alterar perfil
								</S.TextButtonCard>
							</S.ButtonCard>
							<S.ButtonCard
								bg="#FB2424"
								onClick={() => handleCancelPlan(item.id, item.name, item.profile, user.email)}
							>
								<S.TextButtonCard>
									<S.IconButtonCancel />
									Cancelar Assinatura
								</S.TextButtonCard>
							</S.ButtonCard>
						</S.ContentButtonsCard>
					</S.Card>
				))}

				<br /><br /><br /><br /><br /><br />

				<ModalCancel
					modalShow={modalCancelShow}
					modalHide={() => setModalCancelShow(false)}
					subscriptionId={subscriptionId}
					planName={planName}
					planProfile={planProfile}
					planEmail={planEmail}
				>
				</ModalCancel>
			</S.Content>

			<Footer />
		</S.Container>
	);
}

export default Account

