import { useState } from 'react';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Loader from "react-loader-spinner";

import * as S from './styles';

import { useAuth } from '../../hooks/auth';

import ModalMessage from '../../components/ModalMessage';

function Header() {

	const { profile, signOut, loading, noProfile, handleSetNoProfile, subscriptions, handleSelectedProfile, profileSelected } = useAuth();

	const [showMenu, setShowMenu] = useState<boolean>(false);
	const [, setModalMessageShow] = useState<boolean>(true);

	return (
		<>
			<S.HeaderContent>
				<S.Profile>
					{profile.profile ? (
						<S.ImageProfile src={profile.profile} />
					) : (
						<S.NoImageProfile />
					)}

					<S.ButtonNameProfile onClick={() => setShowMenu(!showMenu)}>
						<S.TextButtonNameProfile>{profile.username ? profile.username : 'Selecione um perfil'}</S.TextButtonNameProfile>
					</S.ButtonNameProfile>
					<S.IconChevronDown />
				</S.Profile>
				<S.ContentBalance>
					<S.BalanceLikesAndViews>
						<S.Balance>{profileSelected.balanceLikes}</S.Balance>
						<S.IconBalanceLikes />
					</S.BalanceLikesAndViews>

					<S.BalanceLikesAndViews>
						<S.Balance>{profileSelected.balanceViews}</S.Balance>
						<S.IconBalanceViews />
					</S.BalanceLikesAndViews>
				</S.ContentBalance>

				{showMenu && (
					<S.Menu>
						{subscriptions.map(item => item.type_service === 'followers' && (
							<>
								<button
									key={item.id}
									onClick={() => {
										setShowMenu(!showMenu);
										handleSelectedProfile(item.id, item.profile);
									}}
								>
									<h1>{item.profile}</h1>
								</button>

							</>
						))}

						<button onClick={() => signOut()}>
							<h1>Sair</h1>
						</button>
					</S.Menu>
				)}
			</S.HeaderContent>

			{loading && (
				<ModalMessage
					modalMessageShow={true}
					modalMessageHide={() => setModalMessageShow(false)}
					title="Carregando informações"
				>
					<S.ContentModalMessage>
						<Loader
							type="TailSpin"
							color="#ef295c"
							height={35}
							width={35}
							timeout={0}
						/>

						<p>Pode levar alguns minutos até que finalize!</p>
					</S.ContentModalMessage>
				</ModalMessage>
			)}

			{noProfile && (
				<ModalMessage
					modalMessageShow={true}
					modalMessageHide={() => setModalMessageShow(false)}
					title="Perfil selecionado não encontrado"
				>
					<S.ContentModalMessage>
						<S.IconFiAlertTriangle />

						<p>Por favor tente novamente clicando em <span>ATUALIZAR FOTOS</span> ou entre em contato com nosso suporte.</p>

						<S.ButtonCloseModalMessage
							onClick={() => handleSetNoProfile()}
							type="button"
							model="cancel"
						>
							Fechar
						</S.ButtonCloseModalMessage>
					</S.ContentModalMessage>
				</ModalMessage>
			)}
		</>
	);
}

export default Header;