import { FormEvent, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import * as S from './styles';

import logo from '../../assets/images/logo-panel.svg';

import api from '../../services/api';

interface IParamsProps {
	token: string;
	email: string;
}

export default function ResetPassword() {

	const { token, email } = useParams<IParamsProps>();

	const [password, setPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [message, setMessage] = useState<boolean>(false);

	function handleResetPassword(event: FormEvent) {
		event.preventDefault();

		setLoading(true);

		if (password === '' || confirmPassword === '') {
			setError('Senha e confirmação é obrigatório');
			setLoading(false);

			return;
		}

		if (password !== confirmPassword) {
			setError('As senhas não conferem');
			setLoading(false);

			return;
		}

		api.post('/users/reset-password', {
			email,
			token,
			password
		}, {
			headers: {
				company: 'ig'
			}
		}).then(response => {

			setMessage(true);
		}).catch(error => {

			setLoading(false);
		});
	}

	return (
		<S.Container>
			<S.Header>
				<S.Logo src={logo} />
			</S.Header>

			<S.ContentForm onSubmit={handleResetPassword}>
				{message && (
					<S.ContentForgotPassword>
						<S.IconRiLockPasswordLine />
						<h1>Senha alterada com sucesso!</h1>
						<span>Agora é só fazer login novamente</span>
					</S.ContentForgotPassword>
				)}

				{!message && (
					<>
						<S.Title>Alterar senha?</S.Title>

						<S.InputForm
							label="Senha"
							type="password"
							placeholder="Digite sua nova senha"
							onChange={(event: any) => {
								setPassword(event.target.value);
								setError('');
							}}
						/>

						<S.InputForm
							label="Confirmação"
							type="password"
							placeholder="Confirme a senha"
							onChange={(event: any) => {
								setConfirmPassword(event.target.value);
								setError('');
							}}
						/>

						{error && (
							<p>{error}</p>
						)}

						<S.ButtonAccess
							type="submit"
							model="access"
							loading={loading}
						>
							ENVIAR
						</S.ButtonAccess>
					</>
				)}

				<Link to="/">
					<S.ForgotPassword>
						Fazer login
					</S.ForgotPassword>
				</Link>
			</S.ContentForm>
		</S.Container>
	);
}